import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col, Alert } from 'antd';
import { DefaultAccountRedirect } from '@uz/unitz-tool-components/AppRedirect';
import _ from 'lodash';
import ToolRequestList from '@uz/unitz-tool-components/ToolRequestList';
import DashboardCardItem from '@uz/unitz-tool-components/DashBoardCardItem';
import gstyles from '@vl/gstyles';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <DefaultAccountRedirect>
        <div className="p-4 h-full">
          {!!ctx.get('alerts.length') && (
            <Row className="mb-3" gutter={[16, 16]}>
              {_.map(ctx.get('alerts'), (item, index) => (
                <Col key={index} span={24}>
                  <Alert
                    closable
                    style={{
                      borderRadius: 8,
                      backgroundColor: _.get(gstyles.colors, _.get(item, 'color'), ''),
                      borderColor: _.get(gstyles.colors, _.get(item, 'borderColor'), ''),
                    }}
                    className="border border-brandb500"
                    closeIcon={gstyles.icons({ name: 'close', fill: gstyles.colors.sub })}
                    message={
                      <div className="py-1 flex items-center space-x-2">
                        <div className="pr-2">{_.get(item, 'icon', '')}</div>
                        {_.get(item, 'message', '')}
                      </div>
                    }
                  />
                </Col>
              ))}
            </Row>
          )}

          <Row gutter={[16, 16]} align="middle" justify="space-between">
            {_.map(ctx.get('widgetModel'), (item, index) => (
              <Col key={index} span={8}>
                <DIV>
                  {ctx.debug(() => {
                    ctx.set('@item', item);
                  })}
                  <DashboardCardItem />
                </DIV>
              </Col>
            ))}
          </Row>
          <div className="mt-4">
            <ToolRequestList />
          </div>
        </div>
      </DefaultAccountRedirect>
    </DIV>
  );
};

export default displayName(Index);
