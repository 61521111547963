import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import { decode } from '@vl/mod-utils/jwt';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';
import Link from '@uz/unitz-components-web/Link';
import gstyles from '@vl/gstyles';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchProfile: false }))),
            number_of_teachers: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const account_data = await client.request(
                      gql`
                        query NumberOfStudent {
                          b2b_member_aggregate(where: { member_roles: { role_id: { _eq: "teacher" } }, account_id: {_eq: "${account_id}"} }) {
                            aggregate{
                              count
                            }
                          }
                        }
                      `
                    );
                    return account_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                null
              );

              return _.get(data, 'b2b_member_aggregate.aggregate.count', 0);
            }),

            number_of_students: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const account_data = await client.request(
                      gql`
                        query NumberOfStudent {
                          b2b_member_aggregate(where: { member_roles: { role_id: { _eq: "student" } }, account_id: {_eq: "${account_id}"} }) {
                            aggregate{
                              count
                            }
                          }
                        }
                      `
                    );
                    return account_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                null
              );

              return _.get(data, 'b2b_member_aggregate.aggregate.count', 0);
            }),

            number_of_courses: hook((ctx) => {
              const account_id = ctx.apply('accountModel.getAccountId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_id) return null;
                    const client = await getClient();
                    const account_data = await client.request(
                      gql`
                        query NumberOfStudent {
                          b2b_course_aggregate(where: { account_id: {_eq: "${account_id}"} }) {
                            aggregate{
                              count
                            }
                          }
                        }
                      `
                    );
                    return account_data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                null
              );

              return _.get(data, 'b2b_course_aggregate.aggregate.count', 0);
            }),

            widgetModel: hook((ctx) => {
              const widgets = [
                {
                  title: ctx.apply('i18n.t', 'ZoomToolDashboard.ongoing'),
                  data: ctx.apply('i18n.t', 'ZoomToolDashboard.course', { count: 0 }),
                  directory: () => (
                    <Link className="inline-block" to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                      <div className="flex items-center">
                        <div className="text-brandb500 text-base font-semibold">
                          {ctx.apply('i18n.t', 'ZoomToolDashboard.details')}
                        </div>
                        <div className="pl-2">
                          {gstyles.icons({ name: 'arrow-right2', size: 20, fill: gstyles.colors.brandb500 })}
                        </div>
                      </div>
                    </Link>
                  ),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolDashboard.upcoming'),
                  data: ctx.apply('i18n.t', 'ZoomToolDashboard.course', { count: 0 }),
                  directory: () => (
                    <Link className="inline-block" to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                      <div className="flex items-center">
                        <div className="text-brandb500 text-base font-semibold">
                          {ctx.apply('i18n.t', 'ZoomToolDashboard.details')}
                        </div>
                        <div className="pl-2">
                          {gstyles.icons({ name: 'arrow-right2', size: 20, fill: gstyles.colors.brandb500 })}
                        </div>
                      </div>
                    </Link>
                  ),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolDashboard.finished'),
                  data: ctx.apply('i18n.t', 'ZoomToolDashboard.course', { count: 0 }),
                  directory: () => (
                    <Link className="inline-block" to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                      <div className="flex items-center">
                        <div className="text-brandb500 text-base font-semibold">
                          {ctx.apply('i18n.t', 'ZoomToolDashboard.details')}
                        </div>
                        <div className="pl-2">
                          {gstyles.icons({ name: 'arrow-right2', size: 20, fill: gstyles.colors.brandb500 })}
                        </div>
                      </div>
                    </Link>
                  ),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolDashboard.number_of_students'),
                  data: ctx.get('number_of_students'),
                  directory: () => (
                    <Link className="inline-block" to={ctx.apply('routeStore.toUrl', 'toolAccountStudents')}>
                      <div className="flex items-center">
                        <div className="text-blue500 text-sm">{ctx.apply('i18n.t', 'ZoomToolDashboard.details')}</div>
                        <div className="pl-2">
                          {gstyles.icons({ name: 'arrow-right2', size: 16, fill: gstyles.colors.blue500 })}
                        </div>
                      </div>
                    </Link>
                  ),
                  icon: () => <div>{gstyles.icons({ name: 'student-aggregate', size: 60 })}</div>,
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolDashboard.number_of_teachers'),
                  data: ctx.get('number_of_teachers'),
                  directory: () => (
                    <Link className="inline-block" to={ctx.apply('routeStore.toUrl', 'toolAccountTeachers')}>
                      <div className="flex items-center">
                        <div className="text-blue500 text-sm">{ctx.apply('i18n.t', 'ZoomToolDashboard.details')}</div>
                        <div className="pl-2">
                          {gstyles.icons({ name: 'arrow-right2', size: 16, fill: gstyles.colors.blue500 })}
                        </div>
                      </div>
                    </Link>
                  ),
                  icon: () => gstyles.icons({ name: 'teacher-aggregate', size: 60 }),
                },
                {
                  title: ctx.apply('i18n.t', 'ZoomToolDashboard.number_of_courses'),
                  data: ctx.get('number_of_courses'),
                  directory: () => (
                    <Link className="inline-block" to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                      <div className="flex items-center">
                        <div className="text-blue500 text-sm">{ctx.apply('i18n.t', 'ZoomToolDashboard.details')}</div>
                        <div className="pl-2">
                          {gstyles.icons({ name: 'arrow-right2', size: 16, fill: gstyles.colors.blue500 })}
                        </div>
                      </div>
                    </Link>
                  ),
                  icon: () => gstyles.icons({ name: 'course-aggregate', size: 60 }),
                },
              ];
              return widgets;
            }),

            alerts: hook(() => {
              return [
                {
                  icon: gstyles.icons({ name: 'gg-hello', fill: gstyles.colors.brandb500, size: 24 }),
                  message: 'Welcome to Unitz',
                  color: 'brandb.lightest',
                  borderColor: 'brandb500',
                },
                {
                  color: 'yellow.lightest',
                  borderColor: 'yellow500',
                  icon: gstyles.icons({ name: 'gg-hello', fill: gstyles.colors.yellow500, size: 24 }),
                  message: 'To get support, contact Hoang at: hi@unitz.biz or Diana at: diana.doepfert@unitz.biz. We are here to help you!',
                },
              ];
            }),
          },
        },
      ],
    ],
  },

  // notLoading: {
  //   rules: [
  //     [
  //       'display',
  //       {
  //         display: hook((ctx) => !ctx.apply('loadingModel.isLoading')),
  //       },
  //     ],
  //   ],
  // },
});

export default bindData;
