import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

export default () => {
  return (
    <DIV className="component">
      <div className="border border-border p-4 rounded-xl bg-white500 w-full h-full">
        <div className="flex flex-col md:flex-row items-start md:items-center">
          <div>
            {!!ctx.get('item.icon') && (
              <ResponsiveProps xs={{ size: 50 }} lg={{ size: 60 }}>
                {(resProps) =>
                  gstyles.icons({
                    name: ctx.get('item.icon'),
                    ...resProps,
                  })
                }
              </ResponsiveProps>
            )}
          </div>
          <div className={!!ctx.get('item.icon') ? 'pl-0 md:pl-6' : ''}>
            <div className="text-sub font-normal text-sm mt-4 md:mt-0">{ctx.get('item.title')}</div>
            <div className="text-main my-3 font-semibold text-xl">{ctx.get('item.data')}</div>
            <div>{!!ctx.get('item.directory') && <div>{ctx.apply('item.directory')}</div>}</div>
          </div>
        </div>
      </div>
    </DIV>
  );
};
