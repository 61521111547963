import React from 'react';
import _ from 'lodash';
import { bindings, hook } from '@vl/redata';

export default bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            item: hook((ctx) => {
              return ctx.get('@item');
            }),
          },
        },
      ],
    ],
  },
});
