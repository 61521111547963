import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import { Row, Col } from 'antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';

export default () => {
  return (
    <DIV className="component">
      <div className="bg-brandb500 rounded-lg p-4 mb-4">
        <div className="text-white500 text-sm font-semibold">{ctx.apply('i18n.t', 'ZoomToolDashboard.requests')}</div>
      </div>
      <div className="-mt-4 p-4 rounded-xl shadow-lg bg-white500 py-16">
        {renderPropsComposer(
          {
            matcher: (props) => !_.get(props, 'items.length'),
            render: () => (
              <Row justify="center" className="py-16">
                <Col span={12}>
                  <div className="flex flex-col items-center justify-center opacity-50 select-none">
                    <div className="mb-4">
                      {gstyles.icons({
                        name: 'requests',
                        size: 76,
                        fill: gstyles.colors.sub,
                      })}
                    </div>
                    <div className="mb-6 text-center text-base font-normal text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolDashboard.empty_request')}
                    </div>
                  </div>
                </Col>
              </Row>
            ),
          },
          {
            matcher: (props) => !!_.get(props, 'items.length'),
            render: () => <div></div>,
          },
          () => null
        )(ctx.get('paginationModel'))}
      </div>
    </DIV>
  );
};
